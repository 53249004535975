module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-try-ghost/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://ghost-personal-site-cms.apps.us-west-1.starter.openshift-online.com","contentApiKey":"58b0a8418ead1e19922ae52967"},"production":{"apiUrl":"https://ghost-personal-site-cms.apps.us-west-1.starter.openshift-online.com","contentApiKey":"58b0a8418ead1e19922ae52967"}},"siteConfig":{"siteUrl":"https://monobytes.com","postsPerPage":3,"siteTitleMeta":"Monobytes","siteDescriptionMeta":"Composing 1s and 0s to bring you stories, reviews, and memes","shareImageWidth":1000,"shareImageHeight":523,"shortTitle":"Monobytes","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A"},"routes":{"basePath":"/","collections":[]}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-commento/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://commento.monobytes.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-167612658-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/ghost"],"pageTransitionDelay":0,"defer":false,"sampleRate":100,"siteSpeedSampleRate":100},
    }]
